<template>
  <v-card tile flat>
    <v-card-title>{{ category.title }}</v-card-title>
    <v-card-text>
      <recursive-documents v-if="category.children" :items="category.children" />
    </v-card-text>
  </v-card>
</template>

<script>
import RecursiveDocuments from "./RecursiveDocuments.vue";

export default {
  name: "RecursiveDocumentsCategoryComponent",

  components: {
    RecursiveDocuments
  },

  props: {
    tile: {
      type: [Number, String],
      required: true
    },
    id: {
      type: [Number, String],
      required: true
    }
  },

  computed: {
    appData() {
      return this.$store.getters["app/data"];
    },

    mainTile() {
      const findTile = (tiles, tileId) => {
        for (const tile of tiles) {
          if (tile.id == tileId) {
            return tile;
          }

          if (tile.children) {
            const foundTile = findTile(tile.children, tileId);

            if (foundTile) {
              return foundTile;
            }
          }
        }

        return null;
      };

      return findTile(this.appData.tiles, this.tile);
    },

    category() {
      const categoryId = parseInt(this.id);
      return this.findCategory(this.mainTile?.contentable || [], categoryId);
    }
  },

  methods: {
    findCategory(items, categoryId) {
      for (const item of items) {
        if (item.type.id === 1 && item.id === categoryId) {
          return item;
        }

        if (item.children) {
          const foundCategory = this.findCategory(item.children, categoryId);

          if (foundCategory) {
            return foundCategory;
          }
        }
      }

      return null;
    }
  }
};
</script>
